import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('rcc-text-input',{attrs:{"mask":"##:##","label":_vm.label,"width":_vm.width,"is-disabled":_vm.isDisabled,"rules":_vm.allRules},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"width":"24","height":"24","icon":"","disabled":_vm.isDisabled}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-clock-time-eight")])],1)]}}])},[_c(VTimePicker,{attrs:{"format":"24hr","max":_vm.max,"min":_vm.min},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1)]},proxy:true}]),model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})}
var staticRenderFns = []

export { render, staticRenderFns }